import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { shades, theme } from "../../theme";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  setCurrency
} from "../../state/cart";
import MenuItem from '@mui/material/MenuItem';
import EtnoInput from "../../components/EtnoInput"
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

function Footer() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    palette: { neutral },
  } = useTheme();

  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  const handleChangeCurrency = (e) => {
    dispatch(setCurrency({ currency: e.target.value }))
  };

  const currency = useSelector((state) => state.cart.currency);

  return (
    <Box marginTop="70px" padding="40px 0" backgroundColor={theme.palette.infobar.main}>
      <Box
        width="80%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap="30px"
        columnGap="clamp(20px, 30px, 40px)"
      >
        <Box width="clamp(20%, 25%, 30%)">
          <Typography
            variant="h4"
            fontWeight="bold"
            mb="30px"
            color={shades.secondary[500]}
          >
            ETNOSOUL
          </Typography>
          <Typography color={shades.secondary[500]} mb="20px">© 2024 Etnosoul. All rights reserved.</Typography>
        </Box>

        <Box width="clamp(10%, 10%, 30%)" minWidth={'160px'}>
          <Typography color={shades.secondary[500]} mb="20px">(+38) 066 710 07 93</Typography>
          <Typography color={shades.secondary[500]} mb="20px">
            {t('contacts.we-create-in')}
          </Typography>
          <Typography color={shades.secondary[500]} mb="20px" sx={{ wordWrap: "break-word" }}>
            etnosoul.reply@gmail.com
          </Typography>
        </Box>

        <Box>
          <Typography color={shades.secondary[500]} variant="h4" fontWeight="bold" mb="30px"
            onClick={() => navigate(`/catalog`)}
            style={{ cursor: "pointer" }}>
            {t('generic.women')}
          </Typography>
          <Typography color={shades.secondary[500]} mb="20px"
            onClick={() => navigate(`/catalog/dresses`)}
            style={{ cursor: "pointer" }}>{t('generic.dresses')}</Typography>
          <Typography color={shades.secondary[500]} mb="20px" onClick={() => navigate(`/catalog/skirts`)}
            style={{ cursor: "pointer" }}>{t('generic.skirts')}</Typography>
          <Typography color={shades.secondary[500]} mb="20px" onClick={() => navigate(`/catalog/blouses`)}
            style={{ cursor: "pointer" }}>{t('generic.blouses')}</Typography>
          <Typography color={shades.secondary[500]} mb="20px" onClick={() => navigate(`/catalog/accessories`)}
            style={{ cursor: "pointer" }}>{t('generic.accessories')}</Typography>
        </Box>

        <Box>
          <Typography color={shades.secondary[500]} variant="h4" fontWeight="bold" mb="30px"
            onClick={() => navigate(`/catalog`)}
            style={{ cursor: "pointer" }}>
            {t('generic.men')}
          </Typography>
          <Typography color={shades.secondary[500]} mb="20px" onClick={() => navigate(`/catalog/shirts`)}
            style={{ cursor: "pointer" }}>{t('generic.shirts')}</Typography>
        </Box>

        <Box>
          <Typography color={shades.secondary[500]} variant="h4" fontWeight="bold" mb="30px">
            {t('generic.sitemap').toUpperCase()}
          </Typography>
          <Typography color={shades.secondary[500]} mb="20px" onClick={() => navigate(`/about`)}
            style={{ cursor: "pointer" }}>{t('generic.about')}</Typography>
          <Typography color={shades.secondary[500]} mb="20px" onClick={() => navigate(`/contacts`)}
            style={{ cursor: "pointer" }}>{t('generic.contacts')}</Typography>
          <Typography color={shades.secondary[500]} mb="20px" onClick={() => navigate(`/delivery`)}
            style={{ cursor: "pointer" }}>{t('generic.delivery-and-returns')}</Typography>
          <Typography color={shades.secondary[500]} mb="20px" onClick={() => navigate(`/policy`)}
            style={{ cursor: "pointer" }}>{t('generic.privacy-policy')}</Typography>
        </Box>

        <Box width="clamp(5%, 7%, 10%)">
          <FormControl sx={{ m: 0, mb: 2 }} variant="standard" mb="20px">
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className='info-option'
              value={currency}
              onChange={handleChangeCurrency}
              input={<EtnoInput />}
            >
              <MenuItem value={'UAH'}>UAH</MenuItem>
              <MenuItem value={'USD'}>USD</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ m: 0, mb: 2 }} variant="standard">
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className='info-option'
              value={i18n.language}
              onChange={handleChangeLanguage}
              input={<EtnoInput />}
            >
              <MenuItem value={'uk'}>УКР</MenuItem>
              <MenuItem value={'en'}>EN</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
