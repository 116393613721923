import * as React from 'react';
import { Box, Grid, Button, IconButton, Typography } from "@mui/material";
import AboutImage from '../../assets/etnosoul-about-1.png';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    description: string;
    highlights: string[];
    materials: string[];
}
export default function About(props: Props) {
    const { t, i18n } = useTranslation();
    return (
        <Box className={'info'}>
            <Grid container spacing={4} sx={{
                p: 0,
                direction: 'row',
                alignItems: 'center'
            }}>
                <Grid item md={12} sx={{
                    textAlign: 'center'
                }}>
                    <Typography variant='h1'><Box sx={{ textAlign: 'center', m: 1 }}>{t('about.header')}</Box></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        m: { xs: 4, md: 8 },
                        position: 'relative'
                    }}>
                        <Box sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            display: 'block',
                            zIndex: '-1',
                            m: { xs: -4, md: -8 },
                            objectFit: 'fill'
                        }}>
                            <img src={AboutImage} /></Box>
                        <Typography sx={{
                            fontSize: '1.75rem',
                            fontWeight: '300',
                            lineHeight: '3.125rem',
                            fontFamily: 'Lato',
                            mt: 8
                        }}>{t('about.slogan-1')}</Typography>
                        <Typography sx={{
                            color: 'var(--ORANGE, #F1633F); ',
                            fontSize: { xs: '5rem', md: '10rem' },
                            fontWeight: '700',
                            lineHeight: { xs: '5.125rem', md: '8.75rem' },
                            fontFamily: 'Lato'
                        }}>{t('about.slogan-2')}</Typography>
                        <Typography width={{ md: '40%', xs: '90%' }} sx={{
                            mt: { xs: 6, md: 18 },
                            color: 'var(--GREEN, #0E2A22)',
                            fontSize: '1.25rem',
                            fontWeight: '400',
                            lineHeight: '1.75rem',
                            fontFamily: 'Lato'
                        }}>{t('about.company-text')}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                </Grid>
            </Grid>
        </Box>
    );
}