import Typography from "@mui/material/Typography";

export default function PolicyEn() {
    return (
        <Typography variant={"body1"} sx={{
            mt: { xs: 6, md: 18 },
            color: 'var(--GREEN, #0E2A22)',
            // fontSize: '1.25rem',
            // fontWeight: '400',
            // lineHeight: '1.75rem',
            // fontFamily: 'Lato'
        }}>PRIVACY POLICY<br />
            The policy of privacy and protection of personal data (hereinafter – "Privacy Policy") applies to all information that the website https://etnosoul.com.ua (hereinafter – "Site") receives about the User when using the Site and placing an Order.
            We draw your attention to the fact that this Privacy Policy has been prepared taking into account the latest changes in the legislation regulating the protection of personal data, namely – taking into account the requirements of the General Data Protection Regulation (EU Regulation No. 2016/679 of April 27, 2016 or GDPR – General Data Protection Regulation).
            <br />
            <br />
            DEFINITION OF TERMS<br />
            This clause determines the interpretation of the following terms and definitions used in the Agreement:
            "Confidentiality of personal data" – mandatory for compliance by the Site Administration or another person who has gained access to personal data, the requirement not to allow their distribution without the consent of the subject of personal data or the presence of another legal basis.
            "User" – any natural person who has access to the Site using the Internet.
            "Processing of personal data" – any action (operation) or set of actions (operations) carried out with the use of automation tools or without the use of such tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use , transfer (distribution, provision, access), blocking, deletion, destruction of personal data.
            "Personal data" – any information directly or indirectly related to a certain natural person (subject of personal data).
            "Site" – the web page located under the domain name: https://etnosoul.com.ua and managed by the Seller, including the totality of all information, texts, graphic elements, design elements, images, photos and video materials posted on the Site and other results of intellectual activities, as well as information and technical means of the Site.
            "Cookies" – a small piece of data sent by a web server and such that is stored on the User's computer and forwarded by a web client or web browser to a web server in an HTTP request when trying to open a page of the corresponding site.
            "IP address" – unique network address of a node in a computer network, built according to the IP protocol, which allows identification of the location and other information about the User.
            Terms and definitions not defined in Clause 1.1 of the Privacy Policy are interpreted in the meaning established by the current legislation of Ukraine, and in the absence of such – in their usual sense.
            <br />
            <br />
            GENERAL PROVISIONS
            <br />
            The User's use of the Site means the User's automatic and full agreement with the Privacy Policy and the terms of processing the User's personal data set forth in it.
            If the User does not agree with the terms of the Privacy Policy, the latter is obliged to stop using the Site and leave it immediately.
            <li>The current Privacy Policy applies to the Site or other website, systems and/or software (software) used to fulfill Orders and controlled by the Site Administration.
            </li><li>The Administration of the Site does not control and is not responsible for the sites of third parties to which the User can go through the links available on the Site.
            </li><li>The Site Administration does not verify the authenticity of personal data provided by the User.
                The site administration is the controller of the personal data of the Buyers who placed the Order in accordance with the terms of the Public Offer Agreement posted on the Site. The controller of personal data is the person who determines for what (for what purpose) and how to collect personal data.
            </li>
            <br />
            <br />
            AGE LIMITS
            <br />
            By placing an Order on the Site, the User confirms that he has reached the appropriate age, which allows him to independently enter into relevant agreements.
            In the event that the Site Administration asks the User to give consent to the processing of personal data, he can do so on his own. By placing an Order, the User confirms that he has reached the appropriate age, which allows him to independently consent to the processing of personal data.
            If the User has doubts about whether he can independently give consent to the processing of personal data, he should contact the authorized body for the protection of personal data.
            The Site Administration may ask the User to provide additional documents or go through additional procedures, in order to make sure that he has the right to independently give consent to the processing of personal data. If the Site Administration has reasonable doubts about the age of the User, it may also contact his parents or guardians for the purpose of obtaining consent or approval for the Processing of the User's personal data, if he has not reached the appropriate age.
            <br />
            <br />
            SUBJECT OF PRIVACY POLICY
            <br />
            The valid Privacy Policy establishes the obligations of the Site Administration regarding the non-disclosure and provision of the confidentiality protection regime of personal data, which the User must provide to the Site Administration upon request when placing an Order on the Site.
            Personal data permitted to be processed under this Privacy Policy is provided by the User by filling out certain forms on the Site or in an arbitrary format and may include the following information:
            P.I.B. user;
            contact phone number of the User;
            e-mail address (e-mail) of the User;
            other confidential data about the User's identity or the User's contacts.
            In addition to the data provided for in clause 4.2. of this Privacy Policy, the Site Administration reserves the right to automatically collect the following data during the User's browsing of the Site or its individual pages:
            <li>IP addresses of the electronic computing device (computer, phone, tablet, etc.) of the User;
            </li><li>information about cookies-data stored in the User's browser;
            </li><li>information about electronic computing devices (computers, phones, tablets, etc.) of the User;
            </li><li>information about the User's browser, including name, browser version, etc.;
            </li><li>time of access to the Site;
            </li><li>addresses of the pages of the Site viewed by the User;
            </li><li>addresses of previous pages from which access to the Site was obtained.
            </li>
            <br />
            Disabling the function of collecting cookies by the User may make it impossible to access parts of the Site.
            The site collects confidential data provided for in paragraph 4.2. – 4.3. of this Privacy Policy, including in order to identify and solve technical problems of the Site, to control the load on the Site and to carry out statistical calculations of the effectiveness of advertising campaigns of the Site Administration.
            Any other personal or confidential information, not specified above, is subject to reliable storage and non-distribution, except for the cases provided for in paragraph 7.2. – 7.4. of this Privacy Policy.
            <br />
            <br />
            PURPOSES OF COLLECTION OF PERSONAL USER INFORMATION
            <br />
            The Site Administration may use the User's personal data for the following purposes:
            identification of the User to join the public offer contract remotely.
            granting the User access to personalized resources of the Site;
            establishing feedback with the User, including the direction of messages, requests related to the use of the Site or another website, systems and/or software (software) on which the Course materials are located, provision of services, processing of requests and applications from the User;
            determining the location of the User to fulfill the Order;
            confirmation of the authenticity and completeness of the Personal Data provided by the User;
            notification of the User about the status of the Order execution;
            providing the User with effective customer and technical support in the event of problems related to the use of the Site;
            providing the User with his consent, updates, special offers, price information, news and other information on behalf of the Site Administration;
            carrying out advertising activities with the consent of the User.
            The User's personal data may be used for other purposes not provided for in the Privacy Policy, with the prior consent of the User.
            <br />
            <br />
            DATA STORAGE
            <br />
            The Site Administration stores the User's Personal Data only as long as they are needed by him or the Site Administration, for the purpose specified in the Privacy Policy. The latter applies to any other third party who performs certain actions on behalf of the Site Administration. If the Site Administration does not need certain data about the User and does not need to store them in accordance with the requirements of the law, it will delete them or store them in a way that makes it impossible to identify the User.
            <br />
            <br />
            METHODS, PROCEDURE AND TERMS OF PROCESSING PERSONAL INFORMATION
            <br />
            Processing of the User's personal data is carried out without time limits, in any legal way, including in personal data information systems, with or without the use of automation tools.
            By staying on the Site, the User automatically agrees that the Site Administration has the right to transfer personal data to third parties, exclusively for the purpose of fulfilling the User's order placed on the Site.
            The User agrees that the Site Administration has the right to transfer personal data to third parties, in particular, courier services, postal organizations, telecommunications operators and others, solely for the purpose of fulfilling the User's order placed on the Site. including the calculation for the Order.
            The User's personal data may be transferred to authorized state and judicial bodies only if there are legal grounds and a corresponding requirement.
            In case of loss or disclosure of personal data, the Site Administration informs the User about the loss or disclosure of personal data by any means available to it.
            The Site administration takes the necessary organizational and technical measures to protect personal information from illegal or accidental access, destruction, distortion, blocking, copying, distribution, as well as from other illegal actions of third parties.
            The Administration of the Site, together with the User, takes all necessary measures to prevent losses or other negative consequences caused by the loss or disclosure of the User's personal data.
            <br />
            <br />
            OBLIGATIONS OF THE SITE ADMINISTRATION FOR THE PROTECTION OF PERSONAL DATA
            <br />
            The Site Administration undertakes and guarantees the following:
            use any information received exclusively for the purposes specified in section 5 of the Privacy Policy;
            ensure the preservation of the User's Personal Information;
            not to disclose without the prior written consent of the User, as well as not to sell, exchange, publish or disclose in other possible ways the transferred personal data of the User, with the exception of clause 7.2. – 7.4. Privacy policies;
            take precautions to protect the confidentiality of the User's personal data in accordance with the procedure that is usually used to protect this kind of information in the existing business turnover;
            take actions to suspend the use of personal data related to the relevant User, including, and prevent their deletion, from the moment of the User's application or request, or his legal representative, or an authorized body regarding the protection of the rights of personal data subjects for the verification period, in case of detection of unreliable personal data or illegal actions.
            <br />
            <br />
            LIABILITY OF THE PARTIES
            <br />
            In the event of non-fulfillment of the obligations specified in this Privacy Policy, the Site Administration shall be liable for damages incurred by the User in connection with improper use of personal data, in accordance with current legislation, with the exception of the cases provided for in paragraph 7.2. – 7.4. and clause 9.2. of this Privacy Policy.
            In case of loss or disclosure of the User's Personal Information, the Site Administration is not responsible if this information:
            is publicly known at the time of loss or disclosure;
            received from a third party before the Site Administration receives it;
            disclosed with the consent of the User.
            Administration of the Site in all relations not provided for in clause 9.2. Privacy Policy, is responsible in accordance with the current legislation of Ukraine.
            <br />
            <br />
            DISPUTE RESOLUTION PROCEDURE
            <br />
            All disagreements and disputes related to the legal relationship covered by the applicable Privacy Policy shall be resolved by negotiation between the parties.
            If the Parties, as a result of the negotiations provided for in clause 10.1. of this Privacy Policy, could not reach a mutual agreement, as well as in the event that one of the Parties evades negotiations, the dispute is referred to the court in accordance with the procedure established by the legislation of Ukraine.
            <br />
            <br />
            ADDITIONAL TERMS
            <br />
            The Site Administration has the right to make changes to the valid Privacy Policy without the User's consent and notification.
            The updated Privacy Policy enters into force from the moment it is posted on the Site, unless otherwise provided by the new version of the Privacy Policy.
            The privacy policy is posted on the website of the Site at the address on the Internet: https://etnosoul.com.ua.
            The privacy policy complies with the requirements of the legislation of Ukraine on the protection of personal data and contains detailed information about the data collected, the purposes, methods of their collection, processing, use and protection.
            Note for users from the European Union: the current Privacy Policy is prepared to fulfill the obligations stipulated by the General Data Protection Regulation (EU Regulation No. 2016/679 dated 04/27/2016 or GDPR - General Data Protection Regulation).
            All suggestions or questions regarding the Privacy Policy should be reported through the contacts posted on the Site web page.
        </Typography>
    )
}