import * as React from 'react';
import { Box, Button, IconButton, Grid, Typography } from "@mui/material";
import MessageForm from './MessageForm';
import Information from './Information';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    description: string;
    highlights: string[];
    materials: string[];
}
export default function Contacts(props: Props) {
    const { t, i18n } = useTranslation();
    return (
        <Box className={'info'}>
            <Grid container spacing={4} sx={{
                p: 0,
                direction: 'row',
                alignItems: 'center'
            }}>
                <Grid item md={12} sx={{
                }}>
                    <Typography variant='h1'><Box sx={{ textAlign: 'center', m: 1 }}>{t('generic.contacts').toUpperCase()}</Box></Typography>
                </Grid>
                <Grid item md={6}>
                    <Box sx={{
                        m: { xs: 4, md: 8 }
                    }}>
                        <MessageForm />
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <Box sx={{
                        m: { xs: 4, md: 8 }
                    }}>
                        <Information />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}