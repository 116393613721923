import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { shades } from "../../theme";
import { useTranslation } from 'react-i18next';
import { getTranslatedField } from '../../hooks/useCurrency'
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
} from "../../state/cart";
import { useNavigate } from "react-router-dom";

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function Convert(settings, ammount, currency) {
  var conversionRate = 1;

  if (settings && currency == "USD") {
    conversionRate = settings.conversionRateUSD;
  }
  return (ammount / conversionRate).toFixed(2);
}

function GetCurrencySymbol(currency) {
  if (currency == 'UAH') {
    return "₴";
  } else {
    return "$";
  }
}

function getItemPrice(item) {
  let basePrice = item?.attributes?.price;
  let price = basePrice;
  if (item?.variant) {
    price = item?.attributes?.variants?.find(v => v.name === item.variant).price;
  }

  return price;
}

const CartMenu = () => {
  const settings = useSelector((state) => state.cart.settings);
  const currency = useSelector((state) => state.cart.currency);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);
  const totalPrice = cart.reduce((total, item) => {
    return total + item.count * getItemPrice(item);
  }, 0);
  const { t, i18n } = useTranslation();

  return (
    <Box
      display={isCartOpen ? "block" : "none"}
      backgroundColor="rgba(0, 0, 0, 0.4)"
      position="fixed"
      zIndex={10}
      width="100%"
      height="100%"
      left="0"
      top="40px"
      overflow="auto"
    >
      <Box
        position="fixed"
        right="0"
        bottom="0"
        width="max(400px, 40%)"
        height="100%"
        backgroundColor="white"
      >
        <Box padding="30px" overflow="auto" height="100%">
          {/* HEADER */}
          <FlexBox mb="15px">
            <Typography variant="h3">{t('cart.shopping-bag').toLocaleUpperCase()} ({cart.length})</Typography>
            <IconButton onClick={() => dispatch(setIsCartOpen({}))}>
              <CloseIcon />
            </IconButton>
          </FlexBox>

          {/* CART LIST */}
          <Box>
            {cart.map((item) => (
              <Box key={`${item.attributes.name}-${item.id}`}>
                <FlexBox p="15px 0">
                  <Box m={"5px"} flex="1 1 40%">
                    <img
                      alt={item?.name}
                      width="111px"
                      height="148px"
                      src={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${item?.attributes?.image?.data?.attributes?.formats?.medium?.url}`}
                    />
                  </Box>
                  <Box flex="1 1 100%">
                    <FlexBox mb="5px" width={"max(260px, 30%)"}>
                      <Typography fontWeight="bold">
                        {getTranslatedField(item?.attributes, "name", i18n.language)}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          dispatch(removeFromCart({ id: item.id, size: item.size, variant: item.variant }))
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    </FlexBox>
                    <FlexBox m="15px 0">
                      <Box
                        display="flex"
                        alignItems="center"
                        border={`1.5px solid ${shades.secondary[500]}`}
                      >
                        <IconButton
                          onClick={() =>
                            dispatch(decreaseCount({ id: item.id }))
                          }
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography>{item.count}</Typography>
                        <IconButton
                          onClick={() =>
                            dispatch(increaseCount({ id: item.id }))
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                      <Box sx={{ m: "5px" }}>
                        <Typography fontWeight="normal" textAlign={"center"}>
                          {t('generic.size').toLocaleUpperCase()}:
                        </Typography>
                        <Typography textAlign={"center"} fontWeight="bold">
                          {item.size}
                        </Typography>
                        {item.variant && <>
                          <Typography fontWeight="normal" textAlign={"center"}>
                            {t('generic.length').toLocaleUpperCase()}:
                          </Typography>
                          <Typography fontWeight={"bold"} textAlign={"center"}>{t(`generic.variant.${item.variant}`)}</Typography>
                        </>
                        }
                      </Box>
                      <Typography fontWeight="bold" noWrap="true" >
                        {GetCurrencySymbol(currency)} {Convert(settings, getItemPrice(item), currency)}
                      </Typography>
                    </FlexBox>
                    {item.note &&
                      <FlexBox>
                        <Typography style={{ wordWrap: "break-word", width: "230px" }}>
                          {item.note.length <= 100 ? item.note : (item.note.substr(0, 100) + "...")}
                        </Typography>
                      </FlexBox>}
                  </Box>
                </FlexBox>
                <Divider />
              </Box>
            ))}
          </Box>

          {/* ACTIONS */}
          <Box m="20px 0">
            <FlexBox m="20px 0">
              <Typography fontWeight="bold">{t('generic.subtotal').toLocaleUpperCase()}</Typography>
              <Typography fontWeight="bold">{GetCurrencySymbol(currency)} {Convert(settings, totalPrice, currency)}</Typography>
            </FlexBox>
            <Button
              sx={{
                backgroundColor: "secondary.main",
                color: "neutral.main",
                borderRadius: 0,
                minWidth: "100%",
                padding: "20px 40px",
                m: "20px 0"
              }}
              onClick={() => {
                navigate("/checkout");
                dispatch(setIsCartOpen({}));
              }}
            ><Typography>
                {t('generic.checkout').toLocaleUpperCase()}</Typography>

            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CartMenu;
