import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import AddressForm from "./AddressForm";
import { useTranslation } from 'react-i18next';


const Shipping = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  countries,
  convertCurrency,
  currencySymbol,
  currency,
  deliveries,
  isNonMobile
}) => {

  const { t, i18n } = useTranslation();
  return (
    <Box m="0px auto">
      {/* BILLING FORM */}
      <Box sx={{ mb: "15px" }}>
        <Typography sx={{ mb: "15px" }} fontSize="18px">
          {t('checkout.shipping-information')}
        </Typography>
        <AddressForm
          type="shippingAddress"
          values={values.shippingAddress}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          countries={countries}
          convertCurrency={convertCurrency}
          currencySymbol={currencySymbol}
          currency={currency}
          deliveries={deliveries}
          isNonMobile={isNonMobile}
        />
      </Box>
    </Box>
  );
};

export default Shipping;
