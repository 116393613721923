import Typography from "@mui/material/Typography";

export default function PolicyUkr() {
    return (
        <Typography variant={"body1"} sx={{
            mt: { xs: 6, md: 18 },
            color: 'var(--GREEN, #0E2A22)',
            // fontSize: '1.25rem',
            // fontWeight: '400',
            // lineHeight: '1.75rem',
            // fontFamily: 'Lato'
        }}>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ<br />

            Політика конфіденційності та захисту персональних даних (далі – «Політика конфіденційності») діє відносно всієї інформації, яка розміщена на сайті https: //etnosoul.com.ua (далі -  «Сайт)» та яку ми отримуємо про Користувача під час використання Сайту та здійснення Замовлення.
            Звертаємо Вашу увагу, що дану Політику конфіденційності підготовлено з урахуванням останніх змін в законодавстві, що регулює захист персональних даних, а саме – з урахуванням вимог Загального регламенту захисту даних (Регламент ЄС № 2016/679 від 27.04.2016 року або GDPR – General Data Protection Regulation).
            <br />
            <br />
            ВИЗНАЧЕННЯ ТЕРМІНІВ<br />
            Цим пунктом визначається інтерпретація наведених нижче термінів та дефініцій, що використовуються у Договорі:
            «Конфіденційність персональних даних» – обов'язкова для дотримання Адміністрацією Сайту або іншою особою, яка одержала доступ до персональних даних, вимога не допускати їх поширення без згоди суб'єкта персональних даних або наявності іншої законної підстави.
            «Користувач» – будь-яка фізична особа, яка має доступ до Сайту за допомогою мережі Інтернет.
            «Обробка персональних даних» – будь-яка дія (операція) або сукупність дій (операцій), що здійснюються з використанням засобів автоматизації або без використання таких засобів з персональними даними, включаючи збір, запис, систематизацію, накопичення, зберігання, уточнення (оновлення, зміна), витяг, використання, передачу (поширення, надання, доступ), блокування, видалення, знищення персональних даних.
            «Персональні дані» – будь-яка інформація, що прямо або побічно стосується певної фізичної особи (суб'єкта персональних даних).
            «Сайт» – веб-сторінка, що розташована за доменним ім’ям: https: //etnosoul.com.ua та керована Продавцем, включаючи сукупність всієї розміщеної на Сайті інформації, текстів, графічних елементів, елементів дизайну, зображень, фото та відеоматеріалів та інших результатів інтелектуальної діяльності, а також інформаційних і технічних засобів Сайту.
            «Сookies» – невеликий фрагмент даних, відправлений веб-сервером і такий, що зберігається на комп'ютері Користувача та пересилається веб-клієнтом або веб-браузером веб-серверу в HTTP-запиті при спробі відкрити сторінку відповідного сайту.
            «IP-адреса» – унікальна мережева адреса вузла в комп'ютерній мережі, побудована за протоколом IP, що дозволяє ідентифікувати місце розташування та інші відомості про Користувача.
            Терміни та дефініції, не визначені п. 1.1 Політики конфіденційності, тлумачаться у значенні, встановленому чинним законодавством України, а за відсутності такого – у їх звичайному розумінні.
            <br />
            <br />
            ЗАГАЛЬНІ ПОЛОЖЕННЯ<br />
            Використання Користувачем Сайту означає автоматичну і повну згоду Користувача з Політикою конфіденційності та викладеними у ній умовами обробки персональних даних Користувача.
            У разі, якщо Користувач не згоден з умовами Політики конфіденційності, останній зобов'язаний припинити використання Сайту і негайно покинути його.
            Дійсна Політика конфіденційності застосовується до Сайту або іншого веб-сайту, систем та/або програмного забезпечення (ПО), що використовуються для виконання Замовлень та контролюються Адміністрацією Сайту.
            <li>Адміністрація Сайту не контролює і не несе відповідальності за сайти третіх осіб, на які Користувач може перейти за посиланнями, доступними на Сайті.
            </li><li>Адміністрація Сайту не перевіряє достовірність персональних даних, що надаються Користувачем.
            </li><li>Адміністрація сайту є контролером персональних даних Покупців, що здійснили Замовлення відповідно до умов Договору публічної оферти, розміщеної на Сайті. Контролером персональних даних є особа, яка визначає, для чого (з якою метою) і яким чином збирати персональні дані.
            </li><br />
            <br />
            ВІКОВІ ОБМЕЖЕННЯ<br />
            Оформлюючи Замовлення на Сайті, Користувач підтверджує, що досяг відповідного віку, який дозволяє йому самостійно укладати відповідні угоди.
            У разі, коли Адміністрація Сайту просить Користувача надати згоду на обробку персональних даних, він може зробити це самостійно. Оформлюючи Замовлення, Користувач підтверджує, що досяг відповідного віку, який дозволяє йому самостійно давати згоду на обробку персональних даних.
            У разі, якщо Користувач має сумніви щодо того, чи може він самостійно давати згоду на обробку персональних даних, він повинен звернутися до уповноваженого органу з питань захисту персональних даних.
            Адміністрація Сайту може звернутися до Користувача з проханням надати додаткові документи або пройти додаткові процедури, з метою переконатися, що він має право самостійно давати згоду на обробку персональних даних. Якщо в Адміністрації Сайту виникнуть обґрунтовані сумніви щодо віку Користувача, вона також може звернутися до його батьків або опікунів з метою отримання згоди або схвалення на Обробку персональних даних Користувача, якщо він не досяг відповідного віку.
            <br />
            <br />
            ПРЕДМЕТ ПОЛІТИКИ КОНФІДЕЦІЙНОСТІ<br />
            Дійсна Політика конфіденційності встановлює зобов'язання Адміністрації Сайту щодо нерозголошення та забезпечення режиму захисту конфіденційності персональних даних, які Користувач повинен за вимогою надати Адміністрації Сайту при оформленні Замовлення на Сайті.
            Персональні дані, дозволені до обробки в рамках цієї Політики конфіденційності, надаються Користувачем шляхом заповнення певних форм на Сайті або в довільному форматі і можуть включати в себе наступну інформацію:
            П.І.Б. користувача;
            контактний номер телефону Користувача;
            адреса електронної пошти (e-mail) Користувача;
            інші конфіденційні дані про особу Користувача або контакти Користувача.
            Крім даних, передбачених п. 4.2. цієї Політики конфіденційності, Адміністрація Сайту залишає за собою право автоматичного збору в процесі перегляду Сайту або його окремих сторінок Користувачем, наступних даних:
            <li>IP-адреси електронно-обчислювального пристрою (комп'ютера, телефону, планшета і т.д.) Користувача;
            </li><li>інформації про Сookies-даних, що зберігається в браузері Користувача;
            </li><li>інформації про електронно-обчислювальні пристрої (комп'ютери, телефони, планшети і т.д.) Користувача;
            </li><li>інформації про браузер Користувача, в т.ч. найменування, версію браузера і т.д.;
                часу доступу на Сайт;
            </li><li>адреси сторінок Сайту, які переглядав Користувач;
                адреси попередніх сторінок, з яких було отримано доступ до Сайту.
            </li>Відключення Користувачем функції збору Сookies може спричинити неможливість доступу до частин Сайту.
            Сайт здійснює збір конфіденційних даних, передбачених п.п. 4.2. – 4.3. цієї Політики конфіденційності, в т.ч. з метою виявлення та вирішення технічних проблем Сайту, для контролю навантаження на Сайт і для проведення статистичних підрахунків ефективності рекламних кампаній Адміністрації Сайту.
            Будь-яка інша персональна або конфіденційна інформація, не обумовлена вище, підлягає надійному зберіганню і нерозповсюдженню, за винятком випадків, передбачених у п.п. 7.2. – 7.4. цієї Політики конфіденційності.
            <br />
            <br />
            ЦІЛІ ЗБОРУ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ КОРИСТУВАЧА<br />
            Персональні дані Користувача Адміністрація Сайту може використовувати в цілях:
            ідентифікації Користувача для приєднання до договору публічної оферти дистанційним способом.
            надання Користувачеві доступу до персоналізованих ресурсів Сайту;
            встановлення з Користувачем зворотного зв'язку, включаючи напрямок повідомлень, запитів, що стосуються використання Сайту або іншого веб-сайту, систем та/або програмного забезпечення (ПО), на якому розміщені матеріали Курсу, надання послуг, обробку запитів і заявок від Користувача;
            визначення місця розташування Користувача для виконання Замовлення;
            підтвердження достовірності та повноти Персональних даних, наданих Користувачем;
            повідомлення Користувача про стан виконання Замовлення;
            надання Користувачеві ефективної клієнтської і технічної підтримки при виникненні проблем, пов'язаних з використанням Сайту;
            надання Користувачеві з його згоди, оновлень, спеціальних пропозицій, інформації про ціни, новин та інших відомостей від імені Адміністрації Сайту;
            здійснення рекламної діяльності за згодою Користувача.
            Персональні дані Користувача можуть бути використані для інших цілей, що не передбачені Політикою конфіденційності, за попередньою згодою Користувача.
            <br />
            <br />
            ЗБЕРІГАННЯ ДАНИХ<br />
            Адміністрація Сайту зберігає Персональні дані Користувача тільки до тих пір, поки вони потрібні йому або Адміністрації Сайту, з метою, зазначеною у Політиці конфіденційності. Останнє стосується і будь-якої іншої третьої особи, яка виконує від імені Адміністрації Сайту певні дії. У разі, якщо Адміністрація Сайту не потребуватиме певних даних про Користувача і їх не потрібно буде зберігати відповідно до вимог законодавства, вона видалить їх або буде зберігати таким чином, який унеможливить ідентифікацію Користувача.
            <br />
            <br />
            СПОСОБИ, ПОРЯДОК І ТЕРМІНИ ОБРОБКИ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ<br />
            Обробка персональних даних Користувача здійснюється без обмеження строків, будь-яким законним способом, в тому числі, в інформаційних системах персональних даних, з використанням засобів автоматизації або без використання таких засобів.
            Користувач, своїм перебуванням на Сайті, автоматично погоджується з тим, що Адміністрація Сайту має право передавати персональні дані третім особам, виключно з метою виконання замовлення Користувача, оформленого на Сайті.
            Користувач погоджується з тим, що Адміністрація Сайту має право передавати персональні дані третім особам, зокрема, кур'єрським службам, організаціями поштового зв'язку, операторам електрозв'язку і іншим, виключно з метою виконання замовлення Користувача, оформленого на Сайті, включаючи розрахунок за Замовлення.
            Персональні дані Користувача можуть бути передані уповноваженим державним і судовим органам тільки за наявності законних підстав та відповідної вимоги.
            При втраті або розголошенні персональних даних, Адміністрація Сайту інформує Користувача про втрату або розголошення персональних даних будь-якими доступними їй способами.
            Адміністрація Сайту вживає необхідних організаційних і технічних заходів для захисту персональної інформації від неправомірного або випадкового доступу, знищення, перекручення, блокування, копіювання, поширення, а також від інших неправомірних дій третіх осіб.
            Адміністрація Сайту спільно з Користувачем вживає всіх необхідних заходів щодо запобігання збиткам або інших негативних наслідків, викликаних втратою або розголошенням персональних даних Користувача.
            <br />
            <br />
            ЗОБОВ'ЯЗАННЯ АДМІНІСТРАЦІЇ САЙТУ ІЗ ЗАХИСТУ ПЕРСОНАЛЬНИХ ДАНИХ<br />
            Адміністрація Сайту зобов'язується і гарантує наступне:
            використовувати будь-яку отриману інформацію виключно для цілей, зазначених у розділі 5 Політики конфіденційності;
            забезпечити збереження Персональної інформації Користувача;
            не розголошувати без попередньої письмової згоди Користувача, а також не здійснювати продаж, обмін, публікацію або розголошення іншими можливими способами переданих персональних даних Користувача, за винятком п. п. 7.2. – 7.4. Політики конфіденційності;
            вживати заходів обережності для захисту конфіденційності персональних даних Користувача згідно з порядком, що зазвичай використовується для захисту такого роду інформації в існуючому діловому обороті;
            здійснити дії із призупинення користування персональними даними, що відносяться до відповідного Користувача, у тому числі, й не допускати їх видалення, з моменту звернення або запиту Користувача, або його законного представника, або уповноваженого органу щодо захисту прав суб'єктів персональних даних на період перевірки, у разі виявлення недостовірних персональних даних або неправомірних дій.
            <br />
            <br />
            ВІДПОВІДАЛЬНІСТЬ СТОРІН<br />
            У разі невиконання зобов’язань, визначених цією Політикою конфіденційності, Адміністрація Сайту несе відповідальність за збитки, понесені Користувачем у зв'язку з неправомірним використанням персональних даних, відповідно до чинного законодавства, за винятком випадків, передбачених п.п. 7.2. – 7.4. та п. 9.2. цієї Політики конфіденційності.
            У разі втрати або розголошення Персональної інформації Користувача, Адміністрація Сайту не несе відповідальність, якщо дана інформація:
            є публічно відомою на момент втрати або розголошення;
            отримана від третьої сторони до моменту її отримання Адміністрацією Сайту;
            розголошена за згодою Користувача.
            Адміністрація Сайту у всіх відносинах, не передбачених п. 9.2. Політики конфіденційності, несе відповідальність відповідно до чинного законодавства України.
            ПОРЯДОК ВИРІШЕННЯ СПОРІВ
            Всі розбіжності та спори, пов’язані з правовідносинами, на які поширюється дійсна Політика конфіденційності, вирішуються сторонами шляхом переговорів.
            У разі, якщо Сторони в результаті переговорів, передбачених п. 10.1. цієї Політики конфіденційності, не змогли дійти взаємної згоди, а також у разі, якщо одна з Сторін ухиляється від проведення переговорів, спір передається на розгляд суду у порядку, встановленому законодавством України.
            <br />
            <br />
            ДОДАТКОВІ УМОВИ<br />
            Адміністрація Сайту має право вносити зміни у дійсну Політику конфіденційності без згоди і повідомлення Користувача.
            Оновлена Політика конфіденційності вступає в силу з моменту її розміщення на Сайті, якщо інше не передбачено новою редакцією Політики конфіденційності.
            Політику конфіденційності розміщено на веб-сторінці Сайту за адресою в мережі Інтернет: https://etnosoul.com.ua.
            Політика конфіденційності відповідає вимогам законодавства України про захист персональних даних і містить докладні відомості щодо даних, що збираються, цілі, способи їх збору, обробки, використання і захисту.
            Примітка для користувачів з Європейського Союзу: діюча Політика конфіденційності підготовлена на виконання обов'язків, передбачених Загальним регламентом щодо захисту даних (Регламент ЄС № 2016/679 від 27.04.2016 року або GDPR - General Data Protection Regulation).
            Всі пропозиції або питання по Політиці конфіденційності слід повідомляти через контакти розміщені на веб-сторінці Сайту.
            <br />
            <br />
            З повагою та турботою до Ваших персональних даних, EtnoSoul</Typography>
    )
}