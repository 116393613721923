import * as React from 'react';
import { Box, Button, TextField, Grid, IconButton, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        Lato12Regular: true;
    }
}
export default function MessageForm() {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleSendClick(e: any) {
        e.preventDefault();
        sendMessage();
    }

    async function sendMessage() {
        const requestBody = {
            name,
            phoneNumber,
            email,
            message
        };
        const baseUrl = process.env.REACT_APP_ETNOSOUL_BASE_URL;
        const response = await fetch(`${baseUrl}/api/messages`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        const reference = data.reference;
        setOpen(true);
        setEmail('');
        setName('');
        setPhoneNumber('');
        setMessage('');
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('contacts.message-sent')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('contacts.thank-you')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item justifyContent={'flex-start'}>
                    <Typography color='warning.main' variant='subtitle1'>{t('contacts.have-some-questions').toUpperCase()}</Typography>
                </Grid>
                <Grid item xs={12} justifyContent={'flex-start'}>
                    <Typography variant='h1'>{t('contacts.we-will-be-happy').toUpperCase()}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='Lato12Regular'>{t('contacts.your-name')}</Typography>
                    <TextField fullWidth id="name" label={t('contacts.start-typing-here')} value={name} onChange={e => setName(e.target.value)} variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='Lato12Regular'>{t('contacts.phone-number')}</Typography>
                    <TextField fullWidth id="phone-number" label={t('contacts.start-typing-here')} value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='Lato12Regular'>{t('contacts.your-email')}</Typography>
                    <TextField fullWidth id="email" label={t('contacts.start-typing-here')} value={email} onChange={e => setEmail(e.target.value)} variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='Lato12Regular'>{t('contacts.message')}</Typography>
                    <TextField fullWidth id="message" multiline rows={4} label={t('contacts.start-typing-here')} value={message} onChange={e => setMessage(e.target.value)} variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" onClick={handleSendClick} sx={{ bgcolor: 'warning.main' }}>{t('contacts.send').toUpperCase()}</Button>
                </Grid>
            </Grid>
        </Box>
    );
}