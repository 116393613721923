import * as React from 'react';
import { Box, Grid, Button, IconButton, Typography } from "@mui/material";
import AboutImage from '../../assets/etnosoul-about-1.png';
import { useTranslation } from 'react-i18next';
import PolicyEn from './PolicyEn';
import PolicyUkr from './PolicyUkr';

interface Props {
    title: string;
    description: string;
    highlights: string[];
    materials: string[];
}
export default function Policy(props: Props) {
    const { t, i18n } = useTranslation();
    let isEnglish = i18n.language === "en";
    return (
        <Box className={'info'}>
            <Grid container spacing={4} sx={{
                p: 0,
                direction: 'row',
                alignItems: 'center'
            }}>
                <Grid item md={12} sx={{
                    textAlign: 'center'
                }}>
                    <Typography variant='h1'><Box sx={{ textAlign: 'center', m: 1 }}>{t('policy.header')}</Box></Typography>
                </Grid>
                <Box sx={{
                    ml: "10%",
                    mr: "10%",
                    position: 'relative'
                }}>
                    {isEnglish ? <PolicyEn /> : <PolicyUkr />}
                </Box>
            </Grid>
        </Box>
    );
}