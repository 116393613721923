import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./scenes/home/Home";
import Infobar from "./scenes/global/Infobar";
import Navbar2 from "./scenes/global/Navbar2";
import Footer from "./scenes/global/Footer";
import ItemDetails from "./scenes/itemDetails/ItemDetails";
import CartMenu from "./scenes/global/CartMenu";
import Checkout from "./scenes/checkout/Checkout";
import Confirmation from "./scenes/checkout/Confirmation";
import PaymentFailed from "./scenes/checkout/PaymentFailed";
import Catalog from "./scenes/home/Catalog";
import Contacts from "./scenes/contacts/Contacts";
import About from "./scenes/about/About";
import Policy from "./scenes/policy/Policy";
import Delivery from "./scenes/delivery/Delivery";
import { setSettings } from "./state/cart";
import { setDeliveries } from "./state/cart";
import { useSelector, useDispatch } from "react-redux";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


const LoadSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.cart.settings);
  const deliveries = useSelector((state) => state.cart.deliveries);
  const items = useSelector((state) => state.cart.items);

  async function getSettings() {
    var url = `${process.env.REACT_APP_ETNOSOUL_BASE_URL}/api/settings`;
    const loadedSettings = await fetch(
      url,
      {
        method: "GET"
      }
    );
    const settingsJson = await loadedSettings.json();
    dispatch(setSettings(settingsJson.data.attributes));
  }

  async function getDeliveries() {
    var url = `${process.env.REACT_APP_ETNOSOUL_BASE_URL}/api/deliveries`;
    const items = await fetch(
      url,
      {
        method: "GET"
      }
    );
    const itemsJson = await items.json();
    var deliveryItems = itemsJson.data.map((i) => ({
      "name": i.attributes.name,
      "type": i.attributes.type,
      "region": i.attributes.region,
      "price": i.attributes.price
    }));
    dispatch(setDeliveries(deliveryItems));
  }

  useEffect(() => {
    if (!settings) {
      getSettings();
      getDeliveries();
    }
  }, [settings]);

  return null;
};

function App() {



  return (
    <div className="app">
        <BrowserRouter>
          <Infobar />
          <Navbar2 />
          <ScrollToTop />
          <LoadSettings />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="catalog" element={<Catalog />} />
            <Route path="catalog/:category" element={<Catalog />} />
            <Route path="item/:itemId" element={<ItemDetails />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="checkout/success" element={<Confirmation />} />
            <Route path="checkout/failed" element={<PaymentFailed />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="about" element={<About />} />
            <Route path="policy" element={<Policy />} />
            <Route path="delivery" element={<Delivery />} />
          </Routes>
          <CartMenu />
          <Footer />
        </BrowserRouter>
    </div >
  );
}

export default App;
