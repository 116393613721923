import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Item from "../../components/Item";
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { setItems } from "../../state/cart";
import { useTranslation } from 'react-i18next';

const ShoppingList = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(props.category ?? "all");
  const items = useSelector((state) => state.cart.items);
  const breakPoint = useMediaQuery("(min-width:600px)");
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  async function getItems() {
    var url = `${process.env.REACT_APP_ETNOSOUL_BASE_URL}/api/items?populate=image`;
    const items = await fetch(
      url,
      {
        method: "GET"
      }
    );
    const itemsJson = await items.json();
    dispatch(setItems(itemsJson.data));
  }

  useEffect(() => {
    if (props.category) {
      setValue(props.category);
    }
    getItems();
  }, [props.category]); // eslint-disable-line react-hooks/exhaustive-deps

  let listItems;
  if (value === "all" || value == undefined) {
    listItems = items;
  } else {
    listItems = listItems = items.filter((item) => item.attributes.category === value);
  }

  return (
    <Box width="80%" margin="40px auto">
      <Box
        margin="0 auto"
        display="grid"
        gridTemplateColumns="repeat(auto-fill, 300px)"
        justifyContent="space-around"
        rowGap="20px"
        columnGap="1.33%"
      >
        {listItems.map((item, index) => (
          <Item item={item} key={item.id} />
        ))}
      </Box>
    </Box>
  );
};

export default ShoppingList;
