import { Box, Grid, Button, Container, Stack, IconButton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Item from "../../components/Item";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { addToCart } from "../../state/cart";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useRef } from 'react';
import * as React from 'react';
import Popover from '@mui/material/Popover';
import {
  SideBySideMagnifier
} from "react-image-magnifiers";
import ItemDetailsAccordion from "./ItemDetailsAccordion.tsx";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useCurrencyConvert, getTranslatedField, useCurrencySymbol } from '../../hooks/useCurrency'
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import SizeChartMan from '../../components/SizeChartMan.tsx'
import SizeChartWoman from '../../components/SizeChartWoman.tsx'

const ItemDetails = () => {
  const refToVideo = useRef(null);
  const { t, i18n } = useTranslation();
  const currency = useSelector((state) => state.cart.currency);
  const dispatch = useDispatch();
  const { itemId } = useParams();
  const [count, setCount] = useState(1);
  const [item, setItem] = useState(null);
  const [items, setItems] = useState([]);
  const [size, setSize] = useState("M");
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [note, setNote] = useState(null);
  const [videoActive, setVideoActive] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);
  const styles = {
    select: {
      "&.MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "secondary.main",
          color: 'secondary.main',
        },
        "&:hover fieldset": {
          borderColor: "secondary.main",
          color: 'secondary.main',
        },
        "&.Mui-focused fieldset": {
          borderColor: "secondary.main",
          color: "secondary.main"
        },
        '& .MuiInputBase-input': {
          color: 'secondary.main'
        },
        "& .MuiSvgIcon-root": {
          color: "secondary.main",
        },
      },
    },
    price: {
      color: 'secondary.main'
    },
    oldPrice: {
      color: 'rgba(19, 19, 19, 0.5)',
      textDecoration: "line-through",
      marginTop: '10px',
      marginLeft: '10px'

    },
    textField: {
      '& label.Mui-focused': {
        color: 'secondary.main',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'secondary.main',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'secondary.main',
        },
        '&:hover fieldset': {
          borderColor: 'secondary.main',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'secondary.main',
        },
      },
      marginTop: 1
    },
    popoverRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const StyledMenuItem = styled(MenuItem)({
    // Add your styles here
    color: 'secondary.main', // Example: set the text color to red
  });

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleVariantChange = (event) => {
    setSelectedVariant(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const toggleVideo = (event) => {
    videoActive ? refToVideo.current.pause() : refToVideo.current.play();
    setVideoActive(!videoActive);
  }

  async function getItem() {

    const item = await fetch(
      `${process.env.REACT_APP_ETNOSOUL_BASE_URL}/api/items/${itemId}?populate=image&populate=images&populate=video`,
      {
        method: "GET"
      }
    );
    const itemJson = await item.json();
    setItem(itemJson.data);
  }

  async function getItems() {
    const items = await fetch(
      `${process.env.REACT_APP_ETNOSOUL_BASE_URL}/api/items?populate=image&populate=images`,
      {
        method: "GET"
      }
    );
    const itemsJson = await items.json();
    setItems(itemsJson.data);
  }

  const updateCurrentSlide = (index) => {

    if (slideIndex !== index) {
      setSlideIndex(index);
    }
  };

  const handleClick = (i, event) => {
    setSlideIndex(i);
  };

  useEffect(() => {
    getItem();
    getItems();
  }, [itemId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSizeChartClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function searchItemByName(itemList, name) {
    for (var i = 0; i < itemList.length; i++) {
      if (itemList[i].name === name) {
        return itemList[i];
      }
    }
    // Return null if item with the given name is not found
    return null;
  }

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  const showLength = item?.attributes?.variants != null;
  const variants = item?.attributes?.variants;
  if (variants != null && selectedVariant == null) {
    setSelectedVariant(variants[0].name);
  }
  var price = item?.attributes?.price ?? 0;
  if (selectedVariant) {
    var variant = searchItemByName(variants, selectedVariant);
    price = variant.price;
  }

  const settings = {
    showArrows: true,
    interval: 3500,
    dynamicHeight: false,
    stopOnHover: true,
    infiniteLoop: false,
    showStatus: false,
    transitionTime: 500,
    showThumbs: false,
    showIndicators: true,
    swipeable: false,
    emulateTouch: true,
    autoPlay: false,
    margin: 'auto',
    centerMode: false,
    preventMovementUntilSwipeScrollTolerance: true,
    swipeScrollTolerance: 50
  };
  var images = item?.attributes?.images?.data ?? [];
  let isMan = item?.attributes?.category === "shirts";
  // var highlights = ["100% linen wrap dress", "floral embroidery", "ruffle hem skirt", "long sleeves with button cuff", "detachable belt", "optional pockets"];
  var materials = getTranslatedField(item?.attributes, "materials", i18n.language)?.split(",") ?? [];
  return (
    <Container maxWidth={'150vh'} justifyContent={'center'}>
      <Box className={'info'} ml={'auto'} mr={'auto'} maxWidth={'140vh'}>
        <Box flexWrap="wrap" >
          <Grid container spacing={4}>
            {/* IMAGES */}
            <Grid item xs={12} md={8} >
              <Box sx={{
                display: { xs: 'block', md: 'inline-flex' }
              }} item>
                <Box>
                  <Carousel {...settings}
                    selectedItem={slideIndex}
                    onChange={updateCurrentSlide}
                    renderThumbs={() =>
                      images.map((thumbnail) => (
                        <img style={{ objectFit: "contain" }} src={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${thumbnail?.attributes?.formats?.thumbnail?.url}`} alt={thumbnail.alt} key={thumbnail?.attributes?.formats?.thumbnail?.url} />
                      ))
                    }>
                    {images?.map((imgItem, index) =>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: 1,
                        justifyContent: 'space-between'
                      }}>
                        <Box sx={{ width: '48%' }}>
                          <SideBySideMagnifier
                            imageSrc={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${imgItem?.attributes?.formats?.large?.url}`}
                            imageAlt={imgItem?.attributes?.name}
                            largeImageSrc={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${imgItem?.attributes?.formats?.xlarge?.url ?? imgItem?.attributes?.formats?.large?.url}`}
                            alwaysInPlace={true}
                            transitionSpeed={0.5}
                            transitionSpeedInPlace={0.5}
                          /></Box>
                        <Box sx={{ width: '48%' }}>
                          <SideBySideMagnifier
                            imageSrc={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${images[index + 1]?.attributes?.formats?.large?.url}`}
                            imageAlt={images[index + 1]?.attributes?.name}
                            largeImageSrc={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${images[index + 1]?.attributes?.formats?.large?.url}`}
                            alwaysInPlace={true}
                            transitionSpeed={0.5}
                            transitionSpeedInPlace={0.5}
                          /></Box>
                      </Box>
                    )
                    }
                  </Carousel>
                  <ImageList sx={{ width: { sx: '100%', md: '100%' } }} cols={10} >
                    {images.map((thumbnail, i) => (
                      <ImageListItem key={i} onClick={handleClick.bind(this, i)}>
                        <img
                          srcSet={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${thumbnail?.attributes?.formats?.thumbnail?.url}`}
                          alt={''}
                          src={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${thumbnail?.attributes?.formats?.thumbnail?.url}`}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={4} >
              <Box display={'flex'} sx={{}} mt={0} alignContent={'start'} alignItems={'start'}>
                <CardMedia
                  backgroundColor='red'
                  ref={refToVideo}
                  component='video'
                  margin={"auto"}
                  muted
                  autoPlay
                  itemRef="videoCard"
                  onClick={toggleVideo}
                  src={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${item?.attributes?.video?.data?.attributes?.url}`}
                />
              </Box>
            </Grid> */}

            {/* ACTIONS */}
            <Grid item xs={12} md={4}>
              <Box item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} height={'100%'}>
                <Box m="0px 0 25px 0">
                  <Typography variant="h1">{getTranslatedField(item?.attributes, "name", i18n.language)}</Typography>

                  <Box sx={{ borderTop: 1, marginTop: 3, marginBottom: 1, paddingTop: 3, borderColor: '#D8D3CF', display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h1" sx={styles.price}>{useCurrencySymbol(currency)} {useCurrencyConvert(price, currency)}</Typography>
                    {/* <Typography variant="h2" sx={styles.oldPrice}>{useCurrencySymbol(currency)} {useCurrencyConvert(item?.attributes?.price, currency)}</Typography> */}
                  </Box>
                  {/* <Typography variant="caption">
                    *Final price can be changed due to customizations. Please set the available options below and specify your body measurements.
                  </Typography> */}
                </Box>
                <Stack spacing={2} mb={{ md: '150px', xs: '25px' }}>
                  <Box>
                    <Button aria-describedby={popoverId} variant="contained" onClick={handleSizeChartClick}>
                      {t('generic.size-chart')}
                    </Button>
                    <Popover
                      id={popoverId}
                      open={open}
                      anchorReference={"none"}
                      onClose={handleClose}
                      sx={styles.popoverRoot}
                    >
                      <Box>
                        <Button onClick={handleClose} sx={{
                          position: "absolute", float: "right", right: '0px',
                          top: '0px'
                        }}>
                          <Typography variant="caption">{t('generic.close').toUpperCase()}</Typography>
                        </Button>
                        {isMan ? <SizeChartMan onClick={handleClose} /> : <SizeChartWoman onClick={handleClose} />}

                      </Box>
                    </Popover>
                  </Box>
                  <Box>
                    <FormControl sx={{
                      width: '100%'
                    }}>
                      <InputLabel id="demo-simple-select-label">{t('generic.size').toLocaleUpperCase()}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t('generic.size').toLocaleUpperCase()}
                        value={size}
                        sx={styles.select}
                        onChange={handleSizeChange}
                      >
                        <StyledMenuItem value={"XXS"}>XXS</StyledMenuItem>
                        <StyledMenuItem value={"XS"}>XS</StyledMenuItem>
                        <StyledMenuItem value={"S"}>S</StyledMenuItem>
                        <StyledMenuItem value={"M"}>M</StyledMenuItem>
                        <StyledMenuItem value={"L"}>L</StyledMenuItem>
                        <StyledMenuItem value={"XL"}>XL</StyledMenuItem>
                        <StyledMenuItem value={"2XL"}>2XL</StyledMenuItem>
                        <StyledMenuItem value={"3XL"}>3XL</StyledMenuItem>
                        <StyledMenuItem value={"4XL"}>4XL</StyledMenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {showLength && <Box>
                    <FormControl sx={{
                      width: '100%'
                    }}>
                      <InputLabel id="demo-simple-select-label">{t('generic.length').toLocaleUpperCase()}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t('generic.length').toLocaleUpperCase()}
                        value={selectedVariant}
                        sx={styles.select}
                        onChange={handleVariantChange}
                      >
                        {variants?.map((variant, i) => (
                          <StyledMenuItem value={variant.name}><Box sx={{ width: "100%", display: "flex", justifyContent: 'space-between' }}><Box>{t(`generic.variant.${variant.name}`)}</Box><Box>${variant.price}</Box></Box></StyledMenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>}
                  <Accordion sx={{ color: 'secondary.main', boxShadow: "none", border: '1px solid', borderColor: 'secondary.main' }}>
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon sx={{ color: 'secondary.main' }} />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>{t('details.personalization').toLocaleUpperCase()}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="caption">
                          {t('details.personalization-caption')}
                        </Typography>
                        <FormControl>
                          <TextField
                            variant="outlined"
                            label={t('details.personalization').toLocaleUpperCase()}
                            sx={styles.textField}
                            value={note}
                            onChange={handleNoteChange}
                            multiline
                            minRows={3}
                            maxRows={4}
                          />
                        </FormControl>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  <Box display="flex" alignItems="center" minHeight="50px">
                    <Box
                      display="flex"
                      alignItems="center"
                      border={'1.5px solid'}
                      borderColor={'secondary.main'}
                      mr="20px"
                      p="7px 5px"
                    >
                      <IconButton onClick={() => setCount(Math.max(count - 1, 0))}>
                        <RemoveIcon />
                      </IconButton>
                      <Typography sx={{ p: "0 5px" }}>{count}</Typography>
                      <IconButton onClick={() => setCount(count + 1)}>
                        <AddIcon />
                      </IconButton>
                    </Box>
                    <Button
                      fullWidth
                      sx={{
                        backgroundColor: 'secondary.main',
                        color: "white",
                        borderRadius: 0,
                        padding: "15px 40px"
                      }}
                      onClick={() => dispatch(addToCart({ item: { ...item, count, size, variant: selectedVariant, note } }))}
                    ><Typography>
                        {t('details.add-to-cart').toLocaleUpperCase()}</Typography>
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" flexWrap="wrap" gap="15px">
          <ItemDetailsAccordion
            title={getTranslatedField(item?.attributes, "name", i18n.language)}
            description={getTranslatedField(item?.attributes, "longDescription", i18n.language)}
            // highlights={highlights}
            materials={materials} />
        </Box>

        {/* RELATED ITEMS */}
        <Box mt="50px" width="100%">
          <Typography variant="h3" fontWeight="bold">
            {t('details.related-products')}
          </Typography>
          <Box
            mt="20px"
            display="flex"
            flexWrap="wrap"
            columnGap="1.33%"
            justifyContent="space-between"
          >
            {items.slice(0, 4).map((item, i) => (
              <Item key={`${item.name}-${i}`} item={item} />
            ))}
          </Box>
        </Box>
      </Box>
    </Container >
  );
};

export default ItemDetails;
