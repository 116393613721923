import { createSlice, PayloadAction } from "@reduxjs/toolkit";



interface Item {
    id: number;
    count: number;
    size: string;
    variant: string;
    note: string;
}

export interface CartState {
    isCartOpen: boolean;
    cart: Item[];
    items: any[]; // You may want to replace this 'any' type with a more specific type
    deliveries: any[];
    currency: string;
    settings: Settings | undefined;
}

interface Settings {
    conversionRateUSD: number;
}

const initialState: CartState = {
    isCartOpen: false,
    cart: [],
    items: [],
    deliveries: [],
    currency: "UAH",
    settings: undefined
};


export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setItems: (state, action: PayloadAction<any>) => {
            state.items = action.payload;
        },

        setDeliveries: (state, action: PayloadAction<any>) => {
            state.deliveries = action.payload;
        },

        setSettings: (state, action: PayloadAction<Settings>) => {
            state.settings = action.payload;
        },

        addToCart: (state, action: PayloadAction<{ item: Item }>) => {
            var newItem = action.payload.item;
            if (newItem.size != null) {
                state.cart = [...state.cart, action.payload.item];
            }
        },

        removeFromCart: (state, action: PayloadAction<{ id: number, size: string, variant: string }>) => {
            state.cart = state.cart.filter((item) => !(item.id === action.payload.id && item.size === action.payload.size && item.variant === action.payload.variant));
        },

        increaseCount: (state, action: PayloadAction<{ id: number }>) => {
            state.cart = state.cart.map((item) => {
                if (item.id === action.payload.id) {
                    item.count++;
                }
                return item;
            });
        },

        decreaseCount: (state, action: PayloadAction<{ id: number }>) => {
            state.cart = state.cart.map((item) => {
                if (item.id === action.payload.id && item.count > 1) {
                    item.count--;
                }
                return item;
            });
        },

        setIsCartOpen: (state) => {
            state.isCartOpen = !state.isCartOpen;
        },

        setCurrency: (state, action: PayloadAction<{ currency: string }>) => {
            state.currency = action.payload.currency;
        },
    },
});

export const {
    setItems,
    setDeliveries,
    addToCart,
    removeFromCart,
    increaseCount,
    decreaseCount,
    setIsCartOpen,
    setCurrency,
    setSettings
} = cartSlice.actions;

export default cartSlice.reducer;