import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function DeliveryInfoUkr() {
    return (<Box>
        <Typography margin='15px 0px'>
            Зверніть увагу, що за можливу оплату митних зборів та податків відповідає замовник. Необхідність їх оплати та вартість залежать від країни в яку здійснюватиметься доставка.
        </Typography>
        <Typography margin='15px 0px'>
            Ми надсилаємо трекінговий номер для усіх способів доставки. Якщо у вас виникнуть запитання чи проблеми - ви завжди можете написати чи зателефонувати нам. Ми також можемо відстежувати вашу посилку замість вас та надсилати вам сповіщення, просто зверніться з таким проханням.
        </Typography>
        <Typography variant='caption'>
            Доставка по Україні
        </Typography>
        <Typography marginBottom='15px'>
            Нова Пошта - безкоштовна. Орієнтовні терміни доставки 1-2 дні
        </Typography>

        <Typography variant='caption'>
            Міжнародна доставка
        </Typography>
        <Typography marginBottom='15px'>
            Стандартна доставка - Укрпошта. Орієнтовні терміни доставки:<br />
            <li>Європа: 3-5 тижнів </li>
            <li>США, Японія та Канада: 4-8 тижнів</li>
            <li>інші країни: 8-12 тижнів</li><br />
            Експрес доставка - UPS, DPD post, FedEx (залежить від країни отримувача). Орієнтовні терміни доставки:<br />
            <li>Європа до 7-10 днів</li>
            <li>США та Канада 7-14 днів</li>
            <li>інші країни 10-16 днів</li>
        </Typography>

        <Typography variant="caption">
            Повернення
        </Typography>

        <Typography marginBottom='15px'>
            Ми цінуємо вашу довіру та хочемо зробити ваш досвід покупок якнайкращим. Ми приймаємо повернення протягом 14 днів після отримання вами посилки. Всі речі повинні бути в оригінальному стані з збереженими бірками, етикетками. Будь ласка для уточнення та надання вам інструкцій щодо повернення напишіть нам .
            <br />
            Повернення відбувається за рахунок замовника. Будь ласка, повертаючи замовлення з-за кордону, використовуйте служби доставки, що надають трекінговий номер. ВАЖЛИВО! Індивідуальні замовлення (змінені вироби) не підлягають поверненню.
        </Typography>

        <Typography variant="caption">
            Гарантія
        </Typography>

        <Typography marginBottom='15px'>
            Якщо ви отримали не той чи пошкоджений товар, ми оформляємо повернення або обмін за наш рахунок. Надішліть нам всі деталі та фотопідтвердження будь ласка на пошту.
        </Typography>
    </Box >);
}