import ShoppingList from "./ShoppingList";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

function Catalog() {
  const { category } = useParams();
  return (
    <div className="home">
      <ShoppingList category={category} />
    </div>
  );
}

export default Catalog;
