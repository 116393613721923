import * as React from 'react';
import { Box, Button, TextField, Grid, Stack, IconButton, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useTranslation } from 'react-i18next';
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        Lato12Regular: true;
        Lato30Regular: true;
    }
}
export default function Information() {
    const { t, i18n } = useTranslation();
    const navigateInstagram = () => {
        window.open('https://www.instagram.com/etno.soul/', "_blank", "noreferrer");
    }
    const navigateEtsy = () => {
        window.open('https://www.etsy.com/shop/EtnoSoul', "_blank", "noreferrer");
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} justifyContent={'flex-start'}>
                    <Typography color='warning.main' variant='subtitle1'>{t('contacts.send-message').toUpperCase()}</Typography>
                    <Typography variant='Lato30Regular'>etnosoul.reply@gmail.com</Typography>
                </Grid>
                <Grid item xs={12} justifyContent={'flex-start'}>
                    <Typography color='warning.main' variant='subtitle1'>{t('contacts.give-call').toUpperCase()}</Typography>
                    <Typography variant='Lato30Regular'>+38 (066) 7100793</Typography>
                </Grid>
                <Grid item xs={6} justifyContent={'flex-start'}>
                    <Typography color='warning.main' variant='subtitle1'>{t('contacts.follow-us-on-instagram').toUpperCase()}</Typography>
                    <Box>
                        <ArrowOutwardIcon sx={{ color: 'primary.main' }} />
                        <Typography variant='Lato30Regular' style={{ cursor: "pointer" }} onClick={navigateInstagram}>etno.soul</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} justifyContent={'flex-start'}>
                    <Typography color='warning.main' variant='subtitle1'>{t('contacts.find-us-on-etsy').toUpperCase()}</Typography>
                    <Box>
                        <ArrowOutwardIcon sx={{ color: 'primary.main' }} />
                        <Typography variant='Lato30Regular' style={{ cursor: "pointer" }} onClick={navigateEtsy}>etnosoul</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} justifyContent={'flex-start'}>
                    <Typography color='warning.main' variant='subtitle1'>{t('contacts.work-hours-header').toUpperCase()}</Typography>
                    <Typography variant='Lato30Regular'>{t('contacts.work-hours')}</Typography>
                </Grid>
                <Grid item xs={6} justifyContent={'flex-start'}>
                    <Typography color='warning.main' variant='subtitle1'>{t('contacts.we-create-in-header').toUpperCase()}</Typography>
                    <Typography variant='Lato30Regular'>{t('contacts.we-create-in')}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}