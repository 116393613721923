import { getIn } from "formik";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
const StyledMenuItem = styled(MenuItem)({
  // Add your styles here
  color: 'secondary.main', // Example: set the text color to red
});

const AddressForm = ({
  type,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  countries,
  convertCurrency,
  currencySymbol,
  currency,
  deliveries,
  isNonMobile
}) => {
  const styles = {
    select: {
      "&.MuiOutlinedInput-root": {
        "& fieldset": {
          color: 'secondary.main',
        },
        "&:hover fieldset": {
          color: 'secondary.main',
        },
        "&.Mui-focused fieldset": {
          color: "secondary.main"
        },
        '& .MuiInputBase-input': {
          color: 'secondary.main'
        },
        "& .MuiSvgIcon-root": {
          color: "secondary.main",
        },
      },
    },
  };

  let isUkraine = false;
  if (values.country.toLocaleLowerCase() === "ukraine") {
    isUkraine = true;
  } else {
    isUkraine = false;
  }

  // these functions allow for better code readability
  const formattedName = (field) => `${type}.${field}`;


  const formattedError = (field) =>
    Boolean(
      getIn(touched, formattedName(field)) &&
      getIn(errors, formattedName(field))
    );
  let deliveryType = isUkraine ? "ukraine" : "worldwide";
  let deliveryList = deliveries.filter(d => {
    return d.region === deliveryType;
  }).map((x) => {
    return {
      name: x.name,
      price: `${currencySymbol}${convertCurrency(x.price, currency)}`
    }
  });



  const formattedHelper = (field) =>
    getIn(touched, formattedName(field)) && getIn(errors, formattedName(field));

  const { t, i18n } = useTranslation();
  return (
    <Box
      display="grid"
      gap="15px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      }}
    >
      <TextField
        fullWidth
        type="text"
        label={t('checkout.first-name')}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.firstName}
        name={formattedName("firstName")}
        error={formattedError("firstName")}
        helperText={formattedHelper("firstName")}
        sx={{ gridColumn: "span 2" }}
      />
      <TextField
        fullWidth
        type="text"
        label={t('checkout.last-name')}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.lastName}
        name={formattedName("lastName")}
        error={formattedError("lastName")}
        helperText={formattedHelper("lastName")}
        sx={{ gridColumn: "span 2" }}
      />
      {/* <TextField
        fullWidth
        type="text"
        label="Country"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.country}
        name={formattedName("country")}
        error={formattedError("country")}
        helperText={formattedHelper("country")}
        sx={{ gridColumn: "span 4" }}
      /> */}
      <FormControl fullWidth
        sx={{ gridColumn: "span 2" }}>
        <InputLabel id="demo-simple-select-label">{t('checkout.country')}</InputLabel>
        <Select fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={t('checkout.country')}
          value={values.country}
          sx={styles.select}
          name={formattedName("country")}
          error={formattedError("country")}
          helperText={formattedHelper("country")}
          onChange={handleChange}
        >
          {countries?.map((variant, i) => (
            <StyledMenuItem value={variant}><Box sx={{ width: "100%", display: "flex", justifyContent: 'space-between' }}>{variant}</Box></StyledMenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth
        sx={{ gridColumn: "span 2" }}>
        <InputLabel id="select-delivery-label">{t('checkout.delivery')}</InputLabel>
        <Select fullWidth
          labelId="select-delivery-label"
          id="select-delivery"
          label={t('checkout.delivery')}
          value={values.delivery}
          name={formattedName("delivery")}
          error={formattedError("delivery")}
          helperText={formattedHelper("delivery")}
          sx={styles.select}
          onChange={handleChange}
        >
          {deliveryList?.map((variant, i) => (
            <StyledMenuItem value={variant.name}><Box sx={{ width: "100%", display: "flex", justifyContent: 'space-between' }}>{t(`generic.delivery.${variant.name.toLocaleLowerCase()}`)} - {variant.price}</Box></StyledMenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        type="text"
        label={isUkraine ? t('checkout.address-ukraine-1') : t('checkout.address-1')}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.street1}
        name={formattedName("street1")}
        error={formattedError("street1")}
        helperText={formattedHelper("street1")}
        sx={{ gridColumn: "span 2" }}
      />
      <TextField
        fullWidth
        type="text"
        label={t('checkout.address-2')}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.street2}
        name={formattedName("street2")}
        error={formattedError("street2")}
        helperText={formattedHelper("street2")}
        sx={{ gridColumn: "span 2" }}
      />
      <TextField
        fullWidth
        type="text"
        label={t('checkout.city')}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.city}
        name={formattedName("city")}
        error={formattedError("city")}
        helperText={formattedHelper("city")}
        sx={{ gridColumn: "span 2" }}
      />
      <TextField
        fullWidth
        type="text"
        label={t('checkout.state')}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.state}
        name={formattedName("state")}
        error={formattedError("state")}
        helperText={formattedHelper("state")}
        sx={{ gridColumn: "1fr" }}
      />
      <TextField
        fullWidth
        type="text"
        label={t('checkout.zip-code')}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.zipCode}
        name={formattedName("zipCode")}
        error={formattedError("zipCode")}
        helperText={formattedHelper("zipCode")}
        sx={{ gridColumn: "1fr" }}
      />
    </Box>
  );
};

export default AddressForm;
