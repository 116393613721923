import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useTranslation } from 'react-i18next';

const PaymentFailed = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box m="140px auto" width="80%" height="50vh">
      <Alert severity="error" sx={{fontSize: 25}}>
        <AlertTitle sx={{fontSize: 20}}>{t('checkout.failed.header')}</AlertTitle>
        {t('checkout.failed.text-1')} —{" "}
        <strong>{t('checkout.failed.text-2')}</strong>
      </Alert>
    </Box>
  );
};

export default PaymentFailed;
