import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Badge, Box, IconButton,
  Typography
} from "@mui/material";
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as EtsyIcon } from '../../assets/icons/etsy.svg';
import {
  PersonOutline,
  Instagram,
  EmailOutlined,
  ShoppingCart
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { shades, theme } from "../../theme";
import { useTranslation } from 'react-i18next';
import { useCurrencyConvert } from '../../hooks/useCurrency'
import {
  setCurrency
} from "../../state/cart";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import EtnoInput from "../../components/EtnoInput"

function Infobar() {
  const { t, i18n } = useTranslation();
  const convert = useCurrencyConvert;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const currency = useSelector((state) => state.cart.currency);
  const navigateEtsy = () => {
    window.open('https://www.etsy.com/shop/EtnoSoul', "_blank", "noreferrer");
  }
  const navigateInstagram = () => {
    window.open('https://www.instagram.com/etno.soul/', "_blank", "noreferrer");
  }
  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  const handleChangeCurrency = (e) => {
    dispatch(setCurrency({ currency: e.target.value }))
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height="40px"
      backgroundColor={theme.palette.infobar.main}
      color="black"
      position="fixed"
      top="0"
      left="0"
      zIndex="2"
    >
      <Box
        width="100%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box alignItems="center"
          columnGap="5px"
          display="flex"
          marginLeft="5%"
          onClick={() => navigate("/")}
          sx={{ "&:hover": { cursor: "pointer" } }}
          color={shades.secondary[500]}
        ><EmailOutlined onClick={() => window.open('mailto:etnosoul.reply@gmail.com', "_blank", "noreferrer")} />
          <Box display={{ xs: 'none', md: 'block' }}>
            <Typography >
              etnosoul.reply@gmail.com
            </Typography></Box>
        </Box>
        <Box
          display="flex"
          columnGap="20px"
          marginRight="5%"
          zIndex="2"
          alignItems="center"
        >

          <Box display="flex"
            columnGap="10px"
            marginRight="5%"
            zIndex="2"
            alignItems="center">
            <FormControl sx={{ m: 0 }} variant="standard">
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                className='info-option'
                value={currency}
                onChange={handleChangeCurrency}
                input={<EtnoInput />}
              >
                <MenuItem value={'UAH'}>UAH</MenuItem>
                <MenuItem value={'USD'}>USD</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 0 }} variant="standard">
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                className='info-option'
                value={i18n.language}
                onChange={handleChangeLanguage}
                input={<EtnoInput />}
              >
                <MenuItem value={'uk'}>УКР</MenuItem>
                <MenuItem value={'en'}>EN</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <IconButton
            onClick={() => dispatch(navigateInstagram)}
            sx={{ color: "white" }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            onClick={() => dispatch(navigateEtsy)}
            sx={{ color: "white" }}
          ><SvgIcon component={EtsyIcon} inheritViewBox />
          </IconButton>
          {/* </Badge> */}
        </Box>
      </Box>
    </Box >
  );
}

export default Infobar;
