import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { useTranslation } from 'react-i18next';
import { getTranslatedField } from '../../hooks/useCurrency'
import {
    decreaseCount,
    increaseCount,
    removeFromCart
} from "../../state/cart";
const theme = require('../../theme');

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


function Convert(settings: any, ammount: any, currency: string) {
    var conversionRate = 1;

    if (settings && currency == "USD") {
        conversionRate = settings.conversionRateUSD;
    }
    return (ammount / conversionRate).toFixed(2);
}

function GetCurrencySymbol(currency: string) {
    if (currency == 'UAH') {
        return "₴";
    } else {
        return "$";
    }
}

function getItemPrice(item: any) {
    let basePrice = item?.attributes?.price;
    let price = basePrice;
    if (item?.variant) {
        price = item?.attributes?.variants?.find((v: any) => v.name === item.variant).price;
    }

    return price;
}

export default function Summary(values: any) {
    const settings = useSelector((state: any) => state.cart.settings);
    const currency = useSelector((state: any) => state.cart.currency);
    const deliveries = useSelector((state: any) => state.cart.deliveries);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { shades } = theme;
    const cart = useSelector((state: any) => state.cart.cart);
    let deliveryList = deliveries.filter((d: any) => {
        return d.name === values?.values?.shippingAddress?.delivery;
    }).map((x: any) => {
        return {
            name: x.name,
            price: x.price
        }
    });
    const deliveryPrice = deliveryList[0].price;
    const totalPrice = deliveryPrice + cart.reduce((total: any, item: any) => {
        return total + item.count * getItemPrice(item);
    }, 0);

    return (
        <>
            <Box
                sx={{
                    display: "block",
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    zIndex: 10,
                    width: "100%",
                    height: "100%",
                    left: "0",
                    top: "40px",
                }}
            >
                <Box
                    sx={{
                        height: "100%",
                        backgroundColor: "white"
                    }}
                >
                    <Box padding="30px" overflow="auto" height="100%">
                        {/* HEADER */}
                        <FlexBox mb="15px">
                            <Typography variant="h3">{t('cart.shopping-bag').toLocaleUpperCase()} ({cart.length})</Typography>

                        </FlexBox>

                        {/* CART LIST */}
                        <Box>
                            {cart.map((item: any) => (
                                <Box key={`${item.attributes.name}-${item.id}`}>
                                    <FlexBox p="15px 0">
                                        <Box m={"5px"} flex="1 1 40%">
                                            <img
                                                alt={item?.name}
                                                width="111px"
                                                height="148px"
                                                src={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${item?.attributes?.image?.data?.attributes?.formats?.medium?.url}`}
                                            />
                                        </Box>
                                        <Box flex="1 1 100%">
                                            <FlexBox mb="5px" width={"max(260px, 100%)"}>
                                                <Typography fontWeight="bold">
                                                    {getTranslatedField(item?.attributes, "name", i18n.language)}
                                                </Typography>
                                                <IconButton
                                                    onClick={() =>
                                                        dispatch(removeFromCart({ id: item.id, size: item.size, variant: item.variant }))
                                                    }
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </FlexBox>
                                            <FlexBox m="15px 0">
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    border={`1.5px solid ${shades.secondary[500]}`}
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            dispatch(decreaseCount({ id: item.id }))
                                                        }
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                    <Typography>{item.count}</Typography>
                                                    <IconButton
                                                        onClick={() =>
                                                            dispatch(increaseCount({ id: item.id }))
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box sx={{ m: "5px" }}>
                                                    <Typography fontWeight="normal" textAlign={"center"}>
                                                        {t('generic.size').toLocaleUpperCase()}:
                                                    </Typography>
                                                    <Typography textAlign={"center"} fontWeight="bold">
                                                        {item.size}
                                                    </Typography>
                                                    {item.variant && <>
                                                        <Typography fontWeight="normal" textAlign={"center"}>
                                                            {t('generic.length').toLocaleUpperCase()}:
                                                        </Typography>
                                                        <Typography fontWeight={"bold"} textAlign={"center"}>{t(`generic.variant.${item.variant}`)}</Typography>
                                                    </>
                                                    }
                                                </Box>
                                                <Typography fontWeight="bold">
                                                    {GetCurrencySymbol(currency)} {Convert(settings, getItemPrice(item), currency)}
                                                </Typography>
                                            </FlexBox>
                                            {item.note &&
                                                <FlexBox>
                                                    <Typography style={{ wordWrap: "break-word", width: "230px" }}>
                                                        {item.note.length <= 100 ? item.note : (item.note.substr(0, 100) + "...")}
                                                    </Typography>
                                                </FlexBox>}
                                        </Box>
                                    </FlexBox>
                                    <Divider />
                                </Box>
                            ))}
                        </Box>
                        <Box m="20px 0">
                            <FlexBox m="20px 0">
                                <Typography fontWeight="bold">{t('checkout.delivery').toLocaleUpperCase()} ({t(`generic.delivery.${values?.values?.shippingAddress?.delivery}`)})</Typography>
                                <Typography fontWeight="bold">{GetCurrencySymbol(currency)} {Convert(settings, deliveryPrice, currency)}</Typography>
                            </FlexBox>
                        </Box>

                        {/* ACTIONS */}
                        <Box m="20px 0">
                            <FlexBox m="20px 0">
                                <Typography fontWeight="bold">{t('generic.subtotal').toLocaleUpperCase()}</Typography>
                                <Typography fontWeight="bold">{GetCurrencySymbol(currency)} {Convert(settings, totalPrice, currency)}</Typography>
                            </FlexBox>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}