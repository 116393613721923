import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ShoppingBagOutlined } from "@mui/icons-material";
import { Grid, Badge, Box, IconButton, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { shades } from "../../theme";
import { setIsCartOpen } from "../../state/cart";
import navbarLogo from '../../assets/logo/navbar-logo.png';
import { useTranslation } from 'react-i18next';

export default function Navbar2() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);

  return (
    <Box sx={{
      flexGrow: 1,
      width: '100%',
      height: '70px',
      position: 'fixed',
      pl: '5%',
      top: "40px",
      pr: '5%',
      bgcolor: "rgba(255, 255, 255, 0.95)",
      zIndex: 1
    }}>
      <Grid container spacing={0}>
        <Grid item xs={0} md={1} />
        <Grid item xs={10} md={3} >
          <Box item display="flex"
            justifyContent={{ xs: 'space-around', md: 'flex-start' }}
            columnGap="10px">
            <Box>
              <div class="dropdown">
                <button class="dropbtn"><Typography variant="h5">{t('generic.women')}</Typography></button>
                <div class="dropdown-content">
                  <Typography className='menu-item' style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/catalog/dresses`)}>{t('generic.dresses')}</Typography>
                  <Typography className='menu-item' style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/catalog/skirts`)}>{t('generic.skirts')}</Typography>
                  <Typography className='menu-item' style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/catalog/blouses`)}>{t('generic.blouses')}</Typography>
                  <Typography className='menu-item' style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/catalog/accessories`)}>{t('generic.accessories')}</Typography>
                </div>
              </div>
            </Box>
            <Box>
              <div class="dropdown">
                <button class="dropbtn"><Typography variant="h5">{t('generic.men')}</Typography></button>
                <div class="dropdown-content">
                  <Typography className='menu-item' style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/catalog/shirts`)}>{t('generic.shirts')}</Typography>
                </div>
              </div>
            </Box>
          </Box>

        </Grid>
        <Grid item xs={12} md={4} display={{ xs: 'none', md: 'block' }}>
          <Box
            onClick={() => navigate("/")}
            sx={{
              "&:hover": { cursor: "pointer" },
              display: 'flex',
              justifyContent: 'center',
              p: 1,
              m: 1,
              borderRadius: 1,
            }}
            color={shades.secondary[500]}

            height="40px">
            <img height="40px" src={navbarLogo} />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} display={{ xs: 'none', md: 'block' }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            columnGap="20px"
            zIndex="2">
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
              m: 1,
              borderRadius: 1,
            }}>
              <Typography variant="h5"
                onClick={() => navigate(`/about`)}
                style={{ cursor: "pointer" }}>{t('generic.about').toUpperCase()}</Typography></Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
              m: 1,
              borderRadius: 1,
            }}><Typography variant="h5"
              onClick={() => navigate(`/contacts`)}
              style={{ cursor: "pointer" }}>{t('generic.contacts').toUpperCase()}</Typography></Box>

          </Box>
        </Grid>
        <Grid item xs={2} md={1}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems={'center'}
            height={'100%'}
            zIndex="2">
            <Badge
              badgeContent={cart.length}
              color="primary"
              invisible={cart.length === 0}
              sx={{
                "& .MuiBadge-badge": {
                  right: 8,
                  top: 20,
                  padding: "0 4px",
                  height: "14px",
                  minWidth: "13px",
                },
              }}
            >
              <IconButton
                onClick={() => dispatch(setIsCartOpen({}))}
                sx={{ color: "black" }}
              >
                <ShoppingBagOutlined />
              </IconButton>
            </Badge>
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
}