import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from '../app/store'

export function useCurrency() {
    const [currency, setCurrency] = useState('UAH');
    const handleChange = (newValue: string) => {
        setCurrency(newValue);
    }
    return [currency, handleChange] as const; // infers [boolean, typeof load] instead of (boolean | typeof load)[]
}

export function useCurrencyConvert(ammount: number, currency: string) {

    const settings = useSelector((state: RootState) => state.cart.settings);
    var conversionRate = 1;

    if (settings && currency == "USD") {
        conversionRate = settings.conversionRateUSD;
    }
    return (ammount / conversionRate).toFixed(2);
}

export function useConvert() {

    const settings = useSelector((state: RootState) => state.cart.settings);
    const convertCurrency = (ammount: number, currency: string) => {
        let conversionRate = 1;

        if (settings && currency == "USD") {
            conversionRate = settings.conversionRateUSD;
        }
        return (ammount / conversionRate).toFixed(2);
    }

    const getCurrencySymbol = (currency: string) => {
        if (currency == 'UAH') {
            return "₴";
        } else {
            return "$";
        }
    }

    return {
        convertCurrency,
        getCurrencySymbol
    }
}

export function useCurrencySymbol(currency: string) {
    if (currency == 'UAH') {
        return "₴";
    } else {
        return "$";
    }
}
export function getTranslatedField(item: any, fieldName: string, language: string) {
    if (item) {
        var field = (language == "uk") ? fieldName : fieldName + language.charAt(0).toUpperCase() + language.slice(1);
        return item[field];
    }

    return null;
}