import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function DeliveryInfo() {
    return (<Box>
        <Typography margin='15px 0px'>
            Please note that you might be subject to import duties and taxes depending on the country to which you are ordering to. Customs fees and duties are on the customer’s charge.
        </Typography>
        <Typography margin='15px 0px'>
            We will provide you with tracking number for all delivery methods. If you have any problems, questions or doubts – feel free to contact us and we will help you. We can also track your order and keep you updated, just send a request.
        </Typography>
        <Typography margin='15px 0px'>
            Worldwide delivery. We are shipping your orders. UPDATED INFORMATION (2023):
        </Typography>
        <Typography variant='caption'>
            Standart shipping
        </Typography>
        <Typography marginBottom='15px'>
            Ukrainian post or Nova Poshta <br />
            Estimated shipping time: <br />
            Europe 10-14 days <br />
            USA, Japan and Canada 4-8 weeks <br />
            other countries 8-12 weeks <br />
        </Typography>

        <Typography variant='caption'>
            Express shipping
        </Typography>
        <Typography marginBottom='15px'>
            UPS, DPD post, FedEx (depends on destination). <br />
            Estimated shipping time:<br />
            Europe 5-10 days<br />
            USA and Canada up to 10 days<br />
            other countries up to 2 weeks
        </Typography>

        <Typography variant="caption">
            Delivery within Ukraine:
        </Typography>

        <Typography marginBottom='15px'>
            Nova poshta – free. Estimated shipping time is 1-2 days within the country <br />
            Self-pickup – free
        </Typography>

        <Typography variant="caption">
            Returns
        </Typography>

        <Typography marginBottom='15px'>
            We appreciate your trust and want to develop the best shopping experience. We offer return within 14 days after receipt. All items must be unused, in their original condition, and with product tags attached. Please contact us before returning the item to confirm your next steps.

            Please use trackable post service for return at your own shipping cost. Customs fees and duties are on the customer’s charge.
        </Typography>

        <Typography variant="caption">
            Warranty
        </Typography>
        <Typography marginBottom='15px'>
            If you receive damaged or wrong item, we will arrange exchange or return at our expense. You will have to provide us with all details and confirmation (pictures).
        </Typography>
    </Box>);
}