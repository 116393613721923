import { Box, Button, Typography, IconButton, useMediaQuery } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { shades } from "../../theme";
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(shades.buttons[100]),
  backgroundColor: shades.buttons[100],
  align: "center",
  '&:hover': {
    backgroundColor: shades.buttons[100],
  },
}));
// imports all images from assets folder
const importAll = (r) =>
  r.keys().reduce((acc, item) => {
    acc[item.replace("./", "")] = r(item);
    return acc;
  }, {});

export const heroTextureImports = importAll(
  require.context("../../assets/main", false, /\.(png|jpe?g|svg)$/)
);

const MainCarousel = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  return (
    <Carousel
      infiniteLoop={true}
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <IconButton
          onClick={onClickHandler}
          sx={{
            position: "absolute",
            top: "50%",
            left: "0",
            color: "white",
            padding: "5px",
            zIndex: "10",
          }}
        >
          <NavigateBeforeIcon sx={{ fontSize: 40 }} />
        </IconButton>
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <IconButton
          onClick={onClickHandler}
          sx={{
            position: "absolute",
            top: "50%",
            right: "0",
            color: "white",
            padding: "5px",
            zIndex: "10",
          }}
        >
          <NavigateNextIcon sx={{ fontSize: 40 }} />
        </IconButton>
      )}
    >
      {Object.values(heroTextureImports).map((texture, index) => (
        <Box key={`carousel-image-${index}`}>
          <img
            src={texture}
            alt={`carousel-${index}`}
            style={{
              width: "100%",
              height: "700px",
              objectFit: "cover",
              backgroundAttachment: "fixed",
            }}
          />
          <Box
            color="white"
            padding="40px"
            borderRadius="1px"
            alignItems="center"
            textAlign="left"
            backgroundColor="rgb(0, 0, 0, 0.4)"
            position="absolute"
            top="46%"
            left={isNonMobile ? "30%" : "0"}
            right={isNonMobile ? undefined : "0"}
            margin={isNonMobile ? undefined : "0 auto"}
            maxWidth={isNonMobile ? undefined : "340px"}
          >
            <Typography align="center" variant="h3">{t('generic.spring-is-blooming').toUpperCase()}</Typography>
            <Typography sx={{ fontSize: "3rem", lineHeight: "3.5rem" }}>{t('generic.new-collection').toUpperCase()}</Typography>
            <div className="center"> <ColorButton
              onClick={() => navigate("./catalog")}
              align="center">
              <Typography
                variant="caption"
                fontWeight="bold"
                align="center"
                margin="5px 15px"
                color={'neutral.main'}
                sx={{ textDecoration: "none" }}
              >
                {t('generic.to-the-catalog').toUpperCase()}
              </Typography></ColorButton></div>
          </Box>
        </Box>
      ))}
    </Carousel>
  );
};

export default MainCarousel;
