import { Box } from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function SizeChartMan() {
    const { t, i18n } = useTranslation();
    return (<Box className="size-chart" mb={4} width={{ md: '650px', xs: '400px' }}>
        <h1>{t("size-chart.men-header")} </h1>
        <table border={0} cellPadding={12} cellSpacing={0} width={'100%'}>
            <thead>
                <tr>
                    <td>{t("size-chart.size")}</td>
                    <td>{t("size-chart.neck")}</td>
                    <td>{t("size-chart.chest")}</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>XS</td>
                    <td>14-15" / 36-38 cm</td>
                    <td>34-36" / 88-92 cm</td>
                </tr>
                <tr>
                    <td>S</td>
                    <td>15-15.5" / 38-39 cm </td>
                    <td>36-38" / 93-97 cm</td>
                </tr>
                <tr>
                    <td>M</td>
                    <td>15.5-16" / 40-41 cm</td>
                    <td>38-40" / 98-102 cm</td>
                </tr>
                <tr>
                    <td>L</td>
                    <td>16.5-17" / 42-43 cm</td>
                    <td>40-42" / 103-108 cm</td>
                </tr>
                <tr>
                    <td>XL</td>
                    <td>17-17.5" / 44-45 cm</td>
                    <td>42-44" / 108-114 cm</td>
                </tr>
                <tr>
                    <td>2XL</td>
                    <td>17.5-18" / 45-46 cm</td>
                    <td>45-47" /115-120 cm</td>
                </tr>
                <tr>
                    <td>3XL</td>
                    <td>18-19" / 46-48 cm</td>
                    <td>47-49" / 120-126 cm</td>
                </tr>
            </tbody>
        </table>
    </Box>);
}