import { Box } from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function SizeChartWoman() {
    const { t, i18n } = useTranslation();
    return (<Box className="size-chart" mb={4} width={{ md: '650px', xs: '400px' }}>
        <h1>{t("size-chart.women-header")} </h1>
        <table border={0} cellPadding={12} cellSpacing={0} width={'100%'}>
            <thead>
                <tr>
                    <td>{t("size-chart.size")}</td>
                    <td>{t("size-chart.bust")}</td>
                    <td>{t("size-chart.waist")}</td>
                    <td>{t("size-chart.hips")}</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>XXS</td>
                    <td>31-32" / 79-81 cm</td>
                    <td>24-25" / 60-64 cm</td>
                    <td>34-35" / 86-89 cm</td>
                </tr>
                <tr>
                    <td>XS</td>
                    <td>33-34" / 84-88 cm</td>
                    <td>26-27" / 66-68 cm</td>
                    <td>36-37" / 91-94 cm</td>
                </tr>
                <tr>
                    <td>S</td>
                    <td>35-36" / 89-91 cm </td>
                    <td>28-29" / 71-73 cm</td>
                    <td>38-39" / 96-99 cm</td>
                </tr>
                <tr>
                    <td>M</td>
                    <td>37-39" / 94-98 cm</td>
                    <td>30-32" / 76-81 cm</td>
                    <td>40-41" / 101-104 cm</td>
                </tr>
                <tr>
                    <td>L</td>
                    <td>39-40" / 99-102 cm</td>
                    <td>33-35" / 84-89 cm</td>
                    <td>42-44" / 106-112 cm</td>
                </tr>
                <tr>
                    <td>XL</td>
                    <td>41-43" / 104-109 cm</td>
                    <td>36-38" / 91-96 cm</td>
                    <td>46-48" / 116-122 cm</td>
                </tr>
                <tr>
                    <td>2XL</td>
                    <td>44-46" / 112-117 cm</td>
                    <td>39-41" / 99-104 cm</td>
                    <td>49-51" / 125-130 cm</td>
                </tr>
                <tr>
                    <td>3XL</td>
                    <td>47-48" / 119-122 cm</td>
                    <td>41-44" / 107-112 cm</td>
                    <td>52-53" / 132-135 cm</td>
                </tr>
                <tr>
                    <td>4XL</td>
                    <td>50-53" / 127-135 cm</td>
                    <td>44-48" / 112-120 cm</td>
                    <td>54-56" / 137-142 cm</td>
                </tr>
            </tbody>
        </table>
    </Box>);
}